import Btn from './Btn';
import styled from 'styled-components';

export default styled(Btn)`
  border: 2px solid #ffe5a3;
  background: transparent;
  color: #ffe5a3;
  transition: all .3s ease;

  &:hover,
  &:active {
    background: #ffe5a3;
    color: #000000;
  }
`