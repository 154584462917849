import React from 'react';
import styled from 'styled-components';
import * as StyleVars from './../cssVariables';

const Container = styled.div`
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  padding: 12px 0;
  box-sizing: border-box;
  text-align: center;
`;

const HeroImg = styled.img`
  margin: 0 auto;

  @media(${StyleVars.mediaMobileLarge}) {
    width: 100%;
  }
`

export default function MainHeroText(props) {
  return <div>
    <Container>
      <HeroImg src="/assets/hero-img.svg" alt="Banner Logo" />
    </Container>
  </div>
}