import styled from 'styled-components';
import * as StyleVars from './../cssVariables';

export default styled.div`
  padding: 0 50px;

  @media(${StyleVars.mediaTablet}){
    padding: 0 20px;
  }

  @media(${StyleVars.mediaTabletLarge}) {
    padding: 0;
  }
`