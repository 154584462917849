import styled from 'styled-components';

export const Intro = styled.span`
  color: white;
  position: absolute;
  top: 10px;
  font-style: italic;
  font-family: 'Abitare Sans';
  font-size: 18px;
  left: 6px;
  font-weight: 200;
`;
