import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import range from 'lodash/range';
import last from 'lodash/last';

import { Text18, Title26 } from './Typography';
import Content from './Content';
import LazyImage from './LazyImage';
import * as StyleVars from './../cssVariables';
import OutlinedBtn from './OutlinedBtn';
import { isMobile, elementInViewport } from './../utils';
import { Intro } from './Intro';
import CustomLink from './CustomLink';
import EasingBG from './../images/easing-bg.png';

const Logo = styled.div`
  margin: 0 auto;
  display: block;
  width: 300px;
  position: relative;

  img {
    width: 100%;
  }

  @media (${StyleVars.mediaTabletLarge}) {
    width: 250px;
  }
`;

const RelativeWrapper = styled.div`
  position: relative;
`

const Sticky = styled.div`
  top: 0;
  z-index: 3;
  overflow: hidden;
  position: -webkit-sticky;
  position: ${(props) => (props.sticky ? "sticky" : "static")};
  transition: all 0.4s linear;
  background-color: ${(props) => (props.stickyScroll ? "#06080f" : "transparent")};
`

const Display = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
  text-align: center;
  max-width: 100%;
  display: flex;
  align-items: center;
  ${props => props.sticky ? "height: 150px;" : ""}
`;

const AltWrapper = styled.div`
  display: block;
  max-width: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s linear opacity;
  opacity: ${props => (props.show ? '1' : '0')};
  z-index: 3;
  text-align: center;

  @media (${StyleVars.mediaTablet}) {
    max-width: none;
  }
`;

const MainImage = styled.div`
  transition: 0.3s linear opacity;
  opacity: ${props => (props.show ? '1' : '0')};
  z-index: 3;
  text-align: center;
  padding-top: 7px;
  width: 100%;

  @media (${StyleVars.mediaTablet}) {
    max-width: none;
  }
`;

const Title = styled(Title26)`
  text-align: center;
  font-weight: bold;
  margin: 100px 0;
`;

const StepsContainer = styled.div`
  width: 55%;
  margin: 100px auto;

  @media (${StyleVars.mediaMobile}) {
    width: 90%;
    overflow: hidden;
  }
`;

const Steps = styled.div`
  transition: all 0.3s ease;
`;

const subClassCSS = `
  &.-hidden {
    opacity: 0;
  }

  &.-mid-highlight {
    opacity: .2;
  }

  &.-low-highlight {
    opacity: .1;
  }
`;

const mobileActiveCSS = `
  &.-is-active {
    opacity: 1;
  }
`

const Step = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  transition: opacity 0.3s ease;
  opacity: ${props => (props.active ? '1' : '.4')};

  ${props => props.sticky ? mobileActiveCSS : ''}

  ${props => props.active && subClassCSS}
`;

const StepNumber = styled.div`
  width: 53px;
  height: 51px;
  background: #c5c7ca;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  margin-right: 20px;
  flex-shrink: 0;
`;

const StepDetails = styled(Text18)``;

const StepNotes = styled(Text18)`
  text-align: center;
  line-height: 35px;
`;

const BtnContainer = styled.div`
  text-align: center;
  margin-top: 30px;

  a {
    &:first-child {
      margin-right: 30px;
    }
  }

  @media (${StyleVars.mediaMobile}) {
    a {
      display: block;
      width: 100%;
      box-sizing: border-box;

      &:first-child {
        margin-top: 30px;
        margin-right: 0;
        margin-bottom: 15px;
      }
    }
  }
`;

const EndText = styled(Text18)`
  font-weight: bold;
  margin: 30px 0;
  text-align: center;
`;

const LowerContent = styled.div``;

const InstructionsContainer = styled.section`
  position: relative;
  background: url(${EasingBG});
  background-size: cover;
  padding-top: 250px;

  @media (${StyleVars.mediaMobileNewLarge}) {
    padding-top: 150px;
  }
`;

const BG = styled.div`
overflow: hidden;
width: 100%;
position: absolute;
height: 1000px;
top: -100px;

&:before {
  content: '';
  width: 45%;
  height: 700px;
  display: block;
  position: absolute;
  background: #03030b;
  transform: skewY(-20deg);
  top: 0;
  left: -8%;

  @media (${StyleVars.mediaTabletLarge}) {
    width: 40%;
    height: 600px;
  }

  @media (${StyleVars.mediaMobileNewLarge}) {
    width: 35%;
    height: 460px;
  }

  @media (${StyleVars.mediaTablet}) {
    width: 35%;
  }
}

&:after {
  content: '';
  width: 45%;
  height: 700px;
  display: block;
  position: absolute;
  background: #03030b;
  transform: skewY(20deg);
  top: 0;
  right: -8%;

  @media (${StyleVars.mediaTabletLarge}) {
    width: 40%;
    height: 600px;
  }

  @media (${StyleVars.mediaMobileNewLarge}) {
    width: 35%;
    height: 460px;
  }

  @media (${StyleVars.mediaTablet}) {
    width: 35%;
  }
}

@media (max-width: 414px) {
  &:after,
  &:before {
    display: none;
  }
}

@media (${StyleVars.mediaMobile}) {
  &:before,
  &:after {
    display: none;
  }
}
`

class InstructionsContent extends React.Component {
  state = {
    highlightCursor: 0,
    isHovering: false,
    isOutsideCursorBounds: false,
    stickyScroll: false,
    sticky: false
  };

  constructor(props) {
    super(props);

    this.contentRef = React.createRef();
    this.contentTitleRef = React.createRef();
    this.insContainerRef = React.createRef();
    this._interval = null;
  }

  componentWillUnmount() {
    clearInterval(this._interval);
    if (this.state.sticky) {
      window.removeEventListener('scroll', this.handleMobileScroll);
    }
  }

  componentDidMount() {
    if (!isMobile()) {
      window.onscroll = () => {
        clearInterval(this._interval);
        if (elementInViewport(this.insContainerRef.current)) {
          this.handleDesktopAnimations();
        } else {
          this.setState({ highlightCursor: 0, isHovering: false });
          clearInterval(this._interval);
        }
      };
      return;
    }

    this.setState({sticky: true})
    this.handleMobileScrolling();
  }

  handleMobileScroll = () => {
    const contentTop = this.contentRef.current.getBoundingClientRect().top;
    let stickyScroll = false;

    if (contentTop - 50 <= 0) {
      stickyScroll = true;

      const stepsToCurrent = range(1, 6);
      stepsToCurrent.map(i => {
        const el = document.getElementById(`step-${i}`);
        const elOffsetTop = el.getBoundingClientRect().top
        if(elOffsetTop - 200 < 0) {
          const activeSteps = document.querySelectorAll('.step.-is-active')
          const lastActiveStep = last(activeSteps)
          el.classList.add('-is-active')

          if(lastActiveStep){
            const index = parseInt(lastActiveStep.dataset.stepIndex)
            if(this.state.highlightCursor !== index) this.setState({highlightCursor: index})
          }
        } else {
          el.classList.remove('-is-active')
        }

        return i
      });
    }
    
    if(stickyScroll === this.state.stickyScroll) return;
    this.setState({ stickyScroll });
  }

  handleMobileScrolling = () => {
    window.addEventListener('scroll', this.handleMobileScroll, {
      passive: true
    });
  };

  handleDesktopAnimations = () => {
    this._interval = setInterval(() => {
      if (this.state.isHovering) return;
      const newCursor = this.state.highlightCursor + 1;
      if (newCursor <= 4) return this.setState({ highlightCursor: newCursor });
      this.setState({ highlightCursor: 0 });
    }, 3000);
  };

  handleHoverIn = i => {
    if (isMobile()) return;
    this.setState({ isHovering: true, highlightCursor: i });
  };

  handleHoverOut = () => {
    if (isMobile()) return;
    this.setState({ isHovering: false });
  };

  handleStepClick = i => {
    if (!isMobile()) return;
    this.setState({ isHovering: true, highlightCursor: i });
  };

  render() {
    const { highlightCursor, stickyScroll, sticky } = this.state;

    const steps = [
      'Take a pencil',
      `Chop off the end. Round it off so it doesn't poke you.`,
      `Make a line halfway down the middle. One side is "pending", one side is "done".`,
      <>
        Slide some O-rings onto the "pending" side, as many as you aim to
        execute in the period you're tracking.{' '}
        <CustomLink
          href="https://www.google.com/search?q=cherry+mx+o+rings"
          target="_blank"
        >
          Cherry MX O-rings
        </CustomLink>{' '}
        work well. You can also group them by day.
      </>,
      `You've got yourself a Habit Stick.`,
    ];

    return (
      <InstructionsContainer>
        <BG/>
        <Content>
          <Logo>
            <Intro>introducing...</Intro>
            <img src="/assets/hero-img.svg" alt="Logo" />
          </Logo>
        </Content>
        <LowerContent>
          <Content>
            <Title ref={this.contentTitleRef}>Make Your Habit Stick:</Title>
          </Content>
        </LowerContent>
        <RelativeWrapper>
        <Sticky ref={this.contentRef} stickyScroll={stickyScroll} sticky={sticky}>
          <Content>
            <Display sticky={sticky}>
              <MainImage
                className={highlightCursor === 0 ? 'alt show' : 'alt'}
                show={highlightCursor === 0}
                ref={this.insContainerRef}
              >
                <LazyImage
                  fluid={this.props.images.pencil1.childImageSharp.fluid}
                  loading="eager"
                  alt="Pencil 1"
                />
              </MainImage>

              <AltWrapper
                className={highlightCursor === 1 ? 'alt show' : 'alt'}
                show={highlightCursor === 1}
              >
                <LazyImage
                  fluid={this.props.images.pencil2.childImageSharp.fluid}
                  loading="eager"
                  alt="Pencil 2"
                />
              </AltWrapper>

              <AltWrapper
                className={highlightCursor === 2 ? 'alt show' : 'alt'}
                show={highlightCursor === 2}
              >
                <LazyImage
                  fluid={this.props.images.pencil3.childImageSharp.fluid}
                  loading="eager"
                  alt="Pencil 3"
                />
              </AltWrapper>

              <AltWrapper
                className={highlightCursor === 3 ? 'alt show' : 'alt'}
                show={highlightCursor === 3}
              >
                <LazyImage
                  fluid={this.props.images.pencil4.childImageSharp.fluid}
                  loading="eager"
                  alt="Pencil 4"
                />
              </AltWrapper>

              <AltWrapper
                className={highlightCursor === 4 ? 'alt show' : 'alt'}
                show={highlightCursor === 4}
              >
                <LazyImage
                  fluid={this.props.images.pencil5.childImageSharp.fluid}
                  loading="eager"
                  alt="Pencil 5"
                />
              </AltWrapper>
            </Display>
          </Content>
        </Sticky>
        <LowerContent>
          <Content>
            <StepsContainer>
              <Steps>
                {steps.map((s, i) => (
                  <Step
                    key={i}
                    sticky={sticky}
                    active={!sticky && i === highlightCursor}
                    onMouseEnter={() => this.handleHoverIn(i)}
                    onMouseLeave={this.handleHoverOut}
                    id={`step-${i + 1}`}
                    className="step"
                    data-step-index={i}
                  >
                    <StepNumber>{i + 1}</StepNumber>
                    <StepDetails>{s}</StepDetails>
                  </Step>
                ))}
              </Steps>
            </StepsContainer>
          </Content>
        </LowerContent>
        </RelativeWrapper>
        <LowerContent>
        <Content>
            <StepNotes>
              Whenever you perform your habit, slide an O-ring to the "done"
              side.
            </StepNotes>
            <StepNotes>
              Carry it around in your pocket. Fidget with it.
            </StepNotes>
            <StepNotes>
              Let it remind you of your intent and your progress.
            </StepNotes>

            <EndText>It's as simple that.</EndText>

            <StepNotes>Of course, we do have some tips for you:</StepNotes>

            <BtnContainer>
              <OutlinedBtn as="a" href="/how" target="_blank">
                How to Use The Habit Stick
              </OutlinedBtn>
              <OutlinedBtn as="a" href="/why" target="_blank">
                What Makes it Better
              </OutlinedBtn>
            </BtnContainer>
          </Content>
        </LowerContent>
      </InstructionsContainer>
    );
  }
}

export default function Instructions(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          pencil1: file(relativePath: { eq: "2-pencil[1]_resized.png" }) {
            childImageSharp {
              fluid(
                srcSetBreakpoints: [
                  320
                  500
                  636
                  751
                  863
                  957
                  1124
                  1266
                  1404
                  1579
                ]
                sizes: "(max-width: 1558px) 100vw, 1558px"
                maxWidth: 1579
                maxHeight: 193
                quality: 100
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
          pencil2: file(relativePath: { eq: "2-pencil[2]_resized.png" }) {
            childImageSharp {
              fluid(
                srcSetBreakpoints: [
                  320
                  500
                  636
                  751
                  863
                  957
                  1124
                  1266
                  1404
                  1533
                ]
                sizes: "(max-width: 1558px) 100vw, 1558px"
                maxWidth: 1533
                maxHeight: 193
                quality: 100
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
          pencil3: file(relativePath: { eq: "2-pencil[3]_resized.png" }) {
            childImageSharp {
              fluid(
                srcSetBreakpoints: [
                  320
                  500
                  636
                  751
                  863
                  957
                  1124
                  1266
                  1404
                  1533
                ]
                sizes: "(max-width: 1558px) 100vw, 1558px"
                maxWidth: 1533
                maxHeight: 193
                quality: 100
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
          pencil4: file(relativePath: { eq: "2-pencil[4]_resized.png" }) {
            childImageSharp {
              fluid(
                srcSetBreakpoints: [
                  320
                  500
                  636
                  751
                  863
                  957
                  1124
                  1266
                  1404
                  1533
                ]
                sizes: "(max-width: 1558px) 100vw, 1558px"
                maxWidth: 1533
                maxHeight: 193
                quality: 100
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
          pencil5: file(relativePath: { eq: "2-pencil[5]_resized.png" }) {
            childImageSharp {
              fluid(
                srcSetBreakpoints: [
                  320
                  500
                  636
                  751
                  863
                  957
                  1124
                  1266
                  1404
                  1533
                ]
                sizes: "(max-width: 1558px) 100vw, 1558px"
                maxWidth: 1533
                maxHeight: 193
                quality: 100
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      `}
      render={(data, count) => <InstructionsContent images={data} {...props} />}
    />
  );
}
