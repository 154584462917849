import React from 'react';
import styled from 'styled-components';
import { Title42, Text18 } from './Typography';
import Content from './Content';
import SpacedContent from './SpacedContent';
import CustomLink from './CustomLink';
import { Intro } from './Intro';
import BGTop from './../images/secondsection-bg-top.png';
import BGBottom from './../images/secondsection-bg-bottom.png';
import * as StyleVars from './../cssVariables';

const MainTitle = styled.div`
  position: relative;
`

const TitleIntro = styled(Intro)`
  top: -20px;
  left: -2px;
`

const SectionContainer = styled.section`
  padding: 50px 0 20px;
  z-index: 5;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 500px;
    z-index: 2;
    background-color: #3a2c30;
    top: -20px;
    transform: skewY(-4deg);
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 500px;
    z-index: 1;
    background-color: #292d32;
    top: -20px;
    transform: skewY(2deg);
  }

  @media (${StyleVars.mediaMobile}) {
    padding-top: 50px;
    padding-bottom: 50px;

    &:before, &:after {
      height: 700px;
    }
  }
`;

const SectionContent = styled(Content)`
  z-index: 5;
`

const Title = styled(Title42)`
  color: #ffffff;
  font-weight: 100;
`;

const Text = styled(Text18)`
  margin-bottom: 35px;
`;

export default function SecondSection(props) {
  return (
    <SectionContainer>
      <SectionContent>
        <SpacedContent>
          <MainTitle>
            <TitleIntro>To create a new habit...</TitleIntro>
            <Title>You Need a Habit Tracker</Title>
          </MainTitle>
          <Text>
            The concept is simple: Mark off every time you perform your habit.
            Over time, it builds up to a new identity, and a permanent habit. Go
            out for a run enough times, and you're a runner.
          </Text>
          <Text>
            A habit tracker is not going to do the work for you. It's just a
            tool. But the right tracker used the right way is powerful.
          </Text>
          <Text>
            There are lots of habit trackers out there, and{' '}
            <CustomLink href="/how" target="_blank">
              they share some common pitfalls
            </CustomLink>
            .
          </Text>
        </SpacedContent>
      </SectionContent>
    </SectionContainer>
  );
}
