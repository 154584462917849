import React from 'react';
import styled from 'styled-components'; import YouTube from 'react-youtube';

const VideoContainer = styled.div`
  margin-top: 35px;
  iframe {
    width: 100%;
  }
`

export default function MainHeroVideo(props) {
  const opts = {
    height: '443',
    width: '789',
    playerVars: {
      autoplay: 0,
    },
  };
  return <VideoContainer>
    <YouTube videoId="2BG7lcVzN9c" opts={opts} />
  </VideoContainer>
}