import React from 'react';
import styled from 'styled-components';
import MainHeroText from './MainHeroText';
import MainHeroVideo from './MainHeroVideo';
import { Text18, Title35 } from './Typography';
import Content from './Content';
import SpacedContent from './SpacedContent';
import MainTopBG from './../images/main-bg-top.png';
import HeroBGImg from './../images/hero-bg.png';
import * as StyleVars from './../cssVariables';

const Quote = styled.div`
  background-color: #c5c7ca;
  font-weight: 100;
  padding: 50px;
  margin: 40px 0;

  @media (${StyleVars.mediaMobile}) {
    padding: 20px;
    line-height: 1.2;
  }
`;

const QuoteContent = styled.div`
  font-size: 2.5em;

  @media (${StyleVars.mediaMobile}) {
    font-size: 2em;
  }
`;

const Author = styled(Text18)`
  text-align: right;
  color: black;
`;

const VidText = styled(Text18)`
  padding: 0 10px;
`;

const HintContainer = styled.div`
  margin-top: 60px;
`;

const HintBox = styled.div`
  background: #c5c7ca;
  box-shadow: 0px 45.5357px 48.2143px rgba(0, 0, 0, 0.03),
    0px 22.7901px 24.1307px rgba(0, 0, 0, 0.02281),
    0px 13.7277px 14.5352px rgba(0, 0, 0, 0.0195477),
    0px 8.79742px 9.31491px rgba(0, 0, 0, 0.0171268),
    0px 5.70178px 6.03718px rgba(0, 0, 0, 0.015),
    0px 3.5892px 3.80033px rgba(0, 0, 0, 0.0128732),
    0px 2.06222px 2.18353px rgba(0, 0, 0, 0.0104523),
    0px 0.907637px 0.961028px rgba(0, 0, 0, 0.00719002);
  border-radius: 16.9643px;
  transform: rotate(-5.79deg);
  width: 300px;
  text-align: center;
  padding: 5px 35px 45px;
  box-sizing: border-box;
  margin: 0 auto;
`;

const HintTitle = styled(Title35)`
  color: #632626;
`;

const HintContent = styled(Text18)`
  color: black;
`;

const LogoContainerBG = styled.div`
  background-color: #141617;
`;

const LogoContainer = styled.div`
  padding-bottom: 40px;
  position: relative;
  z-index: 2;
`;

const VidContainer = styled.div`
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 1;
    background: rgba(30, 33, 36, 0.6);
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 200px;
    width: 100%;
    background: red;
    top: -200px;
    left: 0;
    background: url(${MainTopBG});
    z-index: 1;
  }
`;

const HeroContainer = styled.section`
  position: relative;
  background-color: #141617;
`;

const HeroBG = styled.div`
  background-image: url(${HeroBGImg});
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-repeat: no-repeat;
  background-position: 65%;
  background-size: auto 100%;

  @media (${StyleVars.mediaTabletLarge}) {
    background-position: -5%;
  }

  @media (${StyleVars.mediaMobileNewLarge}) {
    background-position: 26%;
  }

  @media (${StyleVars.mediaTablet}) {
    background-position: 30%;
  }
`;

const CustomLink = styled.a`
  color: #ffe5a3;
`;

export default function MainHero(props) {
  return (
    <HeroContainer>
      <HeroBG />
      <LogoContainerBG>
        <LogoContainer>
          <Content>
            <SpacedContent>
              <MainHeroText />
              <MainHeroVideo />
            </SpacedContent>
          </Content>
        </LogoContainer>
      </LogoContainerBG>
      <VidContainer>
        <Content>
          <SpacedContent>
            <VidText>
              So you've decided you're going to change something about your
              life. Maybe you're going to get fitter, or healthier, or lose some
              weight, or work on some other personal goal. However, most of
              these resolutions <strong>end up failing</strong>. As James Clear
              says in{' '}
              <strong>
                <CustomLink href="https://atomichabits.com" target="_blank">
                  Atomic Habits
                </CustomLink>
              </strong>
              ,
            </VidText>
          </SpacedContent>
          <Quote>
            <QuoteContent>
              "You do not rise to the level of your goals: You fall to the level
              of your systems."
            </QuoteContent>
            <Author>– James Clear, Atomic Habits</Author>
          </Quote>
          <SpacedContent>
            <Text18>
              Changing your life means <strong>creating new habits</strong> —
              Whether it's drinking more water, going for a run every day, or
              anything else that's going to result in lasting positive change in
              your life.
            </Text18>
            <HintContainer>
              <HintBox>
                <HintTitle>Hint:</HintTitle>
                <HintContent>
                  Behavior patterns that haven't been working for you are
                  habits, too.
                </HintContent>
              </HintBox>
            </HintContainer>
          </SpacedContent>
        </Content>
      </VidContainer>
    </HeroContainer>
  );
}
