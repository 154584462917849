import React, { useEffect } from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import 'aos/dist/aos.css';
import AOS from 'aos';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { scroller, Element } from 'react-scroll';

import { Text18, Title42 } from './Typography';
import Content from './Content';
import LazyImage from './LazyImage';
import * as StyleVars from './../cssVariables';
import OutlinedBtn from './OutlinedBtn';
import CustomLink from './CustomLink';
import P1 from './../images/p1.svg';
import P2 from './../images/p2.svg';
import P3 from './../images/p3.svg';
import P4 from './../images/p4.svg';

const CustomOutlinedBtn = styled(OutlinedBtn)`
  width: 250px;
`

const Container = styled.div`
  padding: 100px 0;
  background-color: #03030b;
`;

const Title = styled(Title42)`
  font-weight: bold;
  text-align: center;
  margin-bottom: 35px;
`;

const Text = styled(Text18)`
  margin-bottom: 35px;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const ContentText = styled.div`
  width: 55%;
  margin: 100px auto 0;

  @media (${StyleVars.mediaMobile}) {
    width: 100%;
  }

  .carousel .slide {
    background: transparent;
  }
`;

const SingleStick = styled.div`
  @media (${StyleVars.mediaTablet}) {
    display: none;
  }
`;

const Pointers = styled.div`
  img {
    position: absolute;
  }

  .p1 {
    top: 20px;
    left: 90px;
  }

  .p2 {
    top: 21%;
    left: 140px;
  }

  .p3 {
    top: 34%;
    left: 180px;
  }

  .p4 {
    bottom: 40px;
    left: 280px;
  }

  @media (${StyleVars.mediaTablet}) {
    display: none;
  }
`;

const StickWithPointers = styled.div`
  display: none;
  @media (${StyleVars.mediaTablet}) {
    display: block;
  }
`;

const SignupContainer = styled.div`
  margin: 100px 0;
  text-align: center;
`;

const FancyHabitSticksContent = props => {
  useEffect(() => {
    AOS.init();
  }, []);

  const goToSignup = () => {
    scroller.scrollTo('signup', {
      duration: 1000,
      delay: 100,
      smooth: 'easeOutQuart',
    });
  };

  return (
    <Container as={Element} name="fancyStick">
      <Content>
        <LazyImage
          fluid={props.image.stick3.childImageSharp.fluid}
          loading="eager"
          alt="Stick"
        />
        <ContentText>
          <Title>
            <div>Yes, we can</div>
            <div>make you a fancy one</div>
          </Title>

          <Text>"We", by the way, are ZSA Technology Labs.</Text>
          <Text>
            We make{' '}
            <CustomLink href="https://zsa.io/" target="_blank">
              ergonomic keyboards
            </CustomLink>{' '}
            that help people, and we can also create some pretty great Habit
            Sticks.
          </Text>
          <Text>
            You don't need an all-metal Habit Stick. A pencil works just fine.
            If you do want one, though, here's what it might look like:
          </Text>
        </ContentText>

        <ImageContainer>
          <StickWithPointers>
            <LazyImage
              fluid={props.image.stick2.childImageSharp.fluid}
              loading="eager"
              alt="Stick"
            />
          </StickWithPointers>
          <SingleStick>
            <LazyImage
              fluid={props.image.stick.childImageSharp.fluid}
              loading="eager"
              alt="Stick"
            />
          </SingleStick>
          <Pointers>
            <img alt="Pointer" className="p1" data-aos="fade-right" src={P1} />
            <img alt="Pointer" className="p2" data-aos="fade-right" src={P2} />
            <img alt="Pointer" className="p3" data-aos="fade-right" src={P3} />
            <img alt="Pointer" className="p4" data-aos="fade-right" src={P4} />
          </Pointers>
        </ImageContainer>

        <SignupContainer>
          <CustomOutlinedBtn onClick={goToSignup}>I Want One</CustomOutlinedBtn>
        </SignupContainer>

        <Carousel
          renderThumbs={() => null}
          showIndicators={false}
          showStatus={false}
          showArrows={false}
          swipeable={true}
          autoPlay={true}
          infiniteLoop={true}
        >
          <div>
            <img src="/assets/5-overall-view.jpg" alt="Overall View" />
          </div>
          <div>
            <img src="/assets/5-overall-view[2].jpg" alt="Overall View" />
          </div>
          <div>
            <img src="/assets/6-bottom.jpg" alt="Bottom" />
          </div>
          <div>
            <img src="/assets/6-crown-midline.jpg" alt="Crown Middle" />
          </div>
          <div>
            <img src="/assets/6-crown.jpg" alt="Crown" />
          </div>
        </Carousel>
      </Content>
    </Container>
  );
};

export default function FancyHabitSticks(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          stick: file(relativePath: { eq: "fancy-stick-alt3.png" }) {
            childImageSharp {
              fluid(
                srcSetBreakpoints: [320, 500, 636, 751, 863, 957]
                sizes: "(max-width: 1558px) 100vw, 1558px"
                maxWidth: 274
                maxHeight: 1072
                quality: 100
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
          stick2: file(relativePath: { eq: "fancy-stick-alt.png" }) {
            childImageSharp {
              fluid(
                srcSetBreakpoints: [320, 500, 636, 771]
                sizes: "(max-width: 1558px) 100vw, 1558px"
                maxWidth: 771
                maxHeight: 1072
                quality: 100
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
          stick3: file(relativePath: { eq: "5-overall-view-transparent_resized.png" }) {
            childImageSharp {
              fluid(
                srcSetBreakpoints: [320, 500, 636, 771, , 863, 957, 3636]
                sizes: "(max-width: 1558px) 100vw, 1558px"
                maxWidth: 3636
                maxHeight: 277
                quality: 100
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      `}
      render={(data, count) => (
        <FancyHabitSticksContent image={data} {...props} />
      )}
    />
  );
}
