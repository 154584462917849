import React from 'react';
import styled from 'styled-components';
import { Text18, Title42 } from './Typography';
import Content from './Content';

import Apple from './../images/apple.svg';
import Private from './../images/private.svg';
import Pencil from './../images/pencil.svg';
import Time from './../images/time.svg';
import OneFinger from './../images/one_finger.svg';
import Pocket from './../images/pocket.svg';
import Tactile from './../images/tactile.svg';

import BGTop from './../images/features-bg-top.png';

import * as StyleVars from './../cssVariables';

const Center = styled.div`
  text-align: center;
`;

const CardContainer = styled.div`
  margin-top: 35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const Card = styled.div`
  display: flex;
  flex: 0 1 30%;
  background: #c5c7ca;
  box-shadow: 0px 51px 54px rgba(0, 0, 0, 0.03),
    0px 25.5249px 27.0264px rgba(0, 0, 0, 0.02281),
    0px 15.375px 16.2794px rgba(0, 0, 0, 0.0195477),
    0px 9.85311px 10.4327px rgba(0, 0, 0, 0.0171268),
    0px 6.38599px 6.76164px rgba(0, 0, 0, 0.015),
    0px 4.01991px 4.25637px rgba(0, 0, 0, 0.0128732),
    0px 2.30969px 2.44555px rgba(0, 0, 0, 0.0104523),
    0px 1.01655px 1.07635px rgba(0, 0, 0, 0.00719002);
  border-radius: 19px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 50px;
  margin-bottom: 35px;

  @media (${StyleVars.mediaMobile}) {
    flex: 0 1 100%;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CardIcon = styled.div``;

const CardTitle = styled(Text18)`
  color: black;
  font-weight: bold;
  text-align: center;
`;

const FeaturesContainer = styled.section`
  background-color: #11181f;
  position: relative;
  z-index: 1;
  padding-top: 100px;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    background: #11181f;
    height: 350px;
    bottom: -120px;
    background-size: 100%;
    background-repeat: no-repeat;
    transform: skewY(4deg);
  }
`;

export default function Features(props) {
  const features = [
    {
      icon: Apple,
      title: 'Simple, with zero electronics',
    },
    {
      icon: Private,
      title: 'Private by design',
    },
    {
      icon: Pencil,
      title: 'DIY & open source',
    },
    {
      icon: Time,
      title: 'Flexible enough to track any timespan',
    },
    {
      icon: OneFinger,
      title: 'Focused so you can only track one habit at a time',
    },
    {
      icon: Pocket,
      title: 'Portable & waterproof',
    },
    {
      icon: Tactile,
      title: 'Tactile enough so you can monitor and update it by touch alone',
    },
  ];

  return (
    <FeaturesContainer>
      <Content>
        <Center>
          <Title42>We can do better</Title42>
          <Text18>What if there was a habit tracker that was...</Text18>
        </Center>

        <CardContainer>
          {features.map((f, i) => (
            <Card key={i}>
              <CardContent>
                <CardIcon>
                  <img src={f.icon} alt={f.title} />
                </CardIcon>

                <CardTitle>{f.title}</CardTitle>
              </CardContent>
            </Card>
          ))}
        </CardContainer>
      </Content>
    </FeaturesContainer>
  );
}
