import * as React from 'react';
import Layout from './../components/layout';
import SEO from './../components/seo';
import MainHero from './../components/MainHero';
import SecondSection from './../components/SecondSection';
import Features from './../components/Features';
import Instructions from './../components/Instructions';
import FancyHabitStick from './../components/FancyHabitStick';
import SignUp from './../components/SignUp';

// markup
const IndexPage = () => {
  return (
    <Layout>
      <SEO title="A new kind of habit tracker" />

      <MainHero />
      <SecondSection />
      <Features />
      <Instructions />
      <FancyHabitStick />
      <SignUp />
    </Layout>
  );
};

export default IndexPage;
